<template>
<div class="flex justify-center white-bg  w-full">
<div class="faq-view owf-h mw-1920 w-full">
    <div class="faq-top-view pt-200">
        <h1 class="top-h1" data-aos="fade-right" v-if="!loading"> {{Faq.data[`title_${$i18n.locale}`]}} </h1>
        <p class="top-p mt-20px" data-aos="fade-left" v-if="!loading"> {{Faq.data[`description_${$i18n.locale}`]}} </p>
        <el-skeleton v-if="loading" />
        <div class="faq-top-bg">
            <img :src="`https://avior.prestigeautofl.com${Faq.files.faqImage}`"  alt="" v-if="Faq.files.faqImage">
            <img src="@/assets/img/stockbg.svg" alt="" v-else>
        </div>
    </div>
    <div class="flex justify-center" v-loading="loading" style="min-height:300px">
        <div class="faq mw-1920  ">
            <div class="faq-items" v-if="FaqList" >
                <div class="faq-item "  v-for="(faqs,i) in FaqList" :key="i" data-aos="fade-up"> 
                    <h1> {{faqs[`title_${$i18n.locale}`]}} </h1>
                    <Collapce class="mt-20" v-for="item in faqs.faqs" :key="item.id"  :question="item.question_ua" :answer="item.answer_ua"    />
                </div> 
            </div> 
        </div>
    </div>     
</div>

</div>
</template>
<script> 
import Collapce from '../components/collapce.vue'
export default {
    name:'Faq',
    components:{
        Collapce
    },
    data(){
        return{
            loading:false,
            FaqList:[], 
          
        }
    },
    computed:{
        Faq(){
            return this.$store.state.FAQ
        },
    },
    methods:{
        Get(){ 
            this.loading=true
            this.axios.get('/faq-category')
            .then(res=>{
                this.FaqList=res.data.data.data
            }) 
            .finally(()=>{
                this.loading=false
            })
        },
       
    },
    mounted(){
        this.Get() 
    }
    
}
</script>


<style lang="scss">
.faq {
    width: 100%;
    padding: 30px 90px;

    .faq-items {
        display: flex;
        flex-wrap: wrap;
        .faq-item {
            width: 50%;
            padding: 30px;

            h1 {
                font-family: Stem-Medium;
                font-size: 30px;
                line-height: 35px;
                color: var(--other-black);
                margin-bottom: 40px;
            }

            .el-collapse {
                border: none !important;
            }
            .el-collapse-item__header{
                border-bottom: 1.5px solid #DDDFE2;
            }
            .faq-question {
                p{
                font-family: Stem-Regular;
                font-size: 1rem;
                line-height: 1.125rem;
                color: var(--white-black);
                }
                padding-bottom: 15px !important;
                
            }

            .faq-answer {
                font-family: Stem-Regular;
                font-size: 1rem;
                line-height: 1.125rem;
                color: var(--white-black);
            }
        }
    }
}

.faq-top-view {
    width: 100%;
    text-align: center;
    background: var(--bg-top);
    padding: 100px  120px;
    position: relative;
}

.stock-mover {
    transform: translateY(-100px);
}

.faq-top-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 30%;
    }
}

@media screen and(max-width:1100px) {
    .faq-top-view {
        padding: 100px  20px;
    }
    .faq{
        padding: 30px 20px;
    }
}
@media screen and(max-width:950px) {
    .faq-top-view {
        padding: 100px  20px;
    }
    .faq{
        .faq-items {
        display: flex;
        flex-wrap: wrap;
        .faq-item {
            width: 100%;
            padding: 20px 0px;
        }
    }

    }
}

@media screen and(max-width:768px) {
    .faq-top-view {
        padding: 60px 20px;
    }

    .faq-top-bg {
        display: none;
    }
    .faq { 

    .faq-items { 
        .faq-item { 

            h1 {
                text-align: center;
                font-size: 20px;
                line-height: 1.5rem;
            }
  
        }
    }
}
}
</style>
