<template>
    <div class="collapce">
        <div class="collapce-header" @click="active=!active">
            <p> {{question}} </p>
            <img :class="active?'rotate':''" src="@/assets/icons/collapce.svg" alt="">
        </div>
        <el-collapse-transition>
        <div class="collapce-body" v-show="active">
            <div class="collapce-line" :class="active?'collapce-line-active':''"></div>
            <p>
                {{answer}}
            </p>
        </div>
        </el-collapse-transition>
    </div>
</template>
<script> 

export default {
    props:['question', 'answer'],
    data() {
           return{
               active:false
           } 
    },
}
</script>

<style scoped lang="scss">
.collapce{
    border-bottom: 1.5px solid #DDDFE2;
    padding-bottom: 20px;

}
.collapce-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    p{
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 1.125rem; 
        color: var(--white-black);
        margin-right: 10px;
    }
    img{
        transition: all 0.3s ease-in;
        
    }
    .rotate{
        transform: rotate(45deg);
        filter: invert(76%) sepia(64%) saturate(5829%) hue-rotate(322deg) brightness(110%) contrast(100%);
    }
 
}
.collapce-body{
    transition: all 0.3s ease-in;
    .collapce-line{
        width: 0px;
        height: 2px;
        background: #DDDFE2;
        margin: 20px 0px;
        transition: all 0.3s ease-in;
    }
    .collapce-line-active{
        width: 100px;
    }
    p{
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 1.125rem; 
        color: var(--white-black);

    }
}
@media screen and(max-width:768px) {
    .collapce-body{
    p{ 
        font-size: 0.75rem;
        line-height: 1.125rem;  

    }
}
.collapce-header{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    p{ 
        font-size: 0.75rem;
        line-height: 1.125rem;  
    }
    img{ 
        width: 24px; 
    } 
}
    
}
</style>